var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_common_search bbox d-flex align-center",
      class: { active: _vm.active },
    },
    [
      _c("img", {
        staticClass: "icon mr-6",
        attrs: {
          src: require("@/assets/images/shopping_mail/search.png"),
          alt: "",
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.keyword,
            expression: "keyword",
          },
        ],
        ref: "input",
        staticClass: "search bbox",
        attrs: { type: "text", placeholder: "搜索" },
        domProps: { value: _vm.keyword },
        on: {
          focus: function ($event) {
            _vm.active = true
          },
          blur: function ($event) {
            _vm.active = false
            _vm.handleChangeKeyword()
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handleKeydown($event)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.keyword = $event.target.value
          },
        },
      }),
      _vm.keyword !== ""
        ? _c("img", {
            staticClass: "clear",
            attrs: {
              src: require("@/assets/images/shopping_mail/clear.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.keyword = ""
                _vm.$refs.input.focus()
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }