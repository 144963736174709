<template>
  <div
    class="views_shopping_mail_modules_common_search bbox d-flex align-center"
    :class="{active}"
  >
    <img
      src="~@/assets/images/shopping_mail/search.png"
      alt=""
      class="icon mr-6"
    >
    <input
      v-model="keyword"
      type="text"
      class="search bbox"
      placeholder="搜索"
      @focus="active = true;"
      @blur="active = false;handleChangeKeyword();"
      @keydown.enter="handleKeydown($event)"
      ref="input"
    >
    <img
      v-if="keyword!==''"
      src="~@/assets/images/shopping_mail/clear.png"
      alt=""
      class="clear"
      @click.stop="keyword='';$refs.input.focus();"
    >
  </div>
</template>

<script>
export default {
  props: {
    props_keyword: {
      type: String,
      default: '',
    }
  },
  watch:{
    props_keyword: function(val){
      this.keyword = val ?? '';
    }
  },
  mounted(){
    this.keyword = this.props_keyword ?? '';
  },
  data(){
    return {
      active: false,
      keyword: '',
    }
  },
  methods: {
    handleChangeKeyword(params={}){
      const {do_search} = params;
      const {props_keyword, keyword} = this;
      if(props_keyword === keyword.trim()){
        return;
      }
      if(props_keyword === undefined && keyword.trim() === ''){
        return;
      }
      this.$emit('handleChange', {keyword:keyword.trim(), do_search});
    },
    handleKeydown(e){
      const is_chinese = this.$api.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }
      this.handleChangeKeyword({do_search: true});
    },
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_common_search{
  @include transition;
  height       : 40px;
  border-radius: 4px;
  padding      : 0 16px;
  position     : relative;
  border          : 1px solid transparent;
  background: {
    color: #E9E9E9;
  }
  &.active{
    background-color: #fff;
    border-color: $main_blue;
  }
  .search{
    @include placeholder(#999);
    @include transition;
    width           : 200px;
    padding-right   : 20px;
    line-height     : 38px;
    color           : #333;
    font-size       : 14px;
    background-color: transparent;
    border          : none;
    outline         : none;
  }
  .clear{
    position : absolute;
    right    : 20px;
    top      : 50%;
    transform: translateY(-50%);
  }
}
</style>
